import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {Box, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {sectionSpacing} from "../theme";
import {Maps} from "../components/map";
import Situatie from "../components/situatie";


export default () => {
  return (
    <>
      <Toolbar />
      <Box
        marginY={sectionSpacing}
      >
        <Container
          maxWidth={'md'}
          sx={{
            textAlign: 'center'
          }}
        >
          <Typography
            variant={'subtitle1'}
            fontWeight={'bold'}
            textTransform={'uppercase'}
          >
            Heerlijk wonen
          </Typography>
          <Typography variant={'h3'}>
            Een zee aan <Box component={'span'} color={'primary.main'}>mogelijkheden</Box>
          </Typography>
          <Typography paragraph>
            Zeewolde biedt, met haar oppervlakte van 268 vierkante kilometer, tal van mogelijkheden om te genieten van de mooie natuur, de heerlijke strandjes, de uitgebreide bosgebieden en een uitgestrekt polderlandschap.
          </Typography>
          <Typography paragraph>
            Deze plek heeft voor iedereen wat te bieden. Van lekker wandelen langs het water, relaxen op de diverse strandjes tot lekker eten en drinken in het centrum. Het kan allemaal!
          </Typography>
        </Container>
      </Box>
      <Container
        maxWidth={'lg'}
      >
        <Box marginY={sectionSpacing}>
          <Situatie />
        </Box>
        <Box marginY={sectionSpacing}>
          <Maps />
        </Box>
        <Box marginY={sectionSpacing}>
          <StaticImage
            src="../images/afbeelding locatie De Wachters.png"
            alt="header"
            layout={'fullWidth'}
            style={{
              // You can set a maximum height for the image, if you wish.
              maxHeight: 800,
            }}
          />
        </Box>
      </Container>
    </>
  )
}
