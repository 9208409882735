import React from 'react'
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api"
import {graphql, useStaticQuery} from "gatsby";

const position = {
    lat: 52.352055, lng: 5.532829
}

export const Maps = () => {

    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: {eq: "icon.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 20, quality: 100, layout: FIXED)
                }
            }
        }
    `)
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyBiHp9dCSpUzmUa5RDzk800d-njJkpeqVY"
        >
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '100%'
                }}
                center={position}
                zoom={16}
            >
                <Marker
                    position={position}
                    icon={data.logo.childImageSharp.gatsbyImageData.src}
                />
            </GoogleMap>
        </LoadScript>
    );
}
